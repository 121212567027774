<template>
  <ModalComp
    title-text="Order Details"
    :show="modelValue"
    @hide="toggleModal(false)"
    max-width="900px"
    :show-action-button="false"
    title-icon-class="me-1 icon-assignment"
  >
    <div
      v-if="state.orderData?.id"
      class="order-actions mt-2"
    >
      <div class="order-details">
        <div class="container">
          <div class="row" v-if="state.orderData">
            <div
              v-if="!state.orderData.last_email_send_status"
              class="col-12 p-0"
            >
              <Message severity="error" :closable="false">
                Last email sent to this orders owner was unsuccessful.
              </Message>
            </div>

            <!--     Item Details       -->
            <div class="col-lg-6 col-sm-12 col-12 mb-3 p-0">
              <div class="lead">Item Details</div>
              <div class="item-summery fs18 text-black bg-light rounded p-2">
                <div class="order-type">
                  <div class="d-flex justify-content-between">
                    <div class="">Type:</div>
                    <div class="val text-capitalize">{{ state.orderData.type }}</div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>Requested:</div>
                    <div class="val">
                      {{
                        state.orderData.requested_time_is_asap
                          ? 'ASAP'
                          : formatDTime(state.orderData.requested_delivery_time, true)
                      }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>Ordered:</div>
                    <div class="val">{{ formatDTime(state.orderData.created_at, true) }}</div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>Confirmed:</div>
                    <div class="val">{{ formatDTime(state.orderData.confirmation_time) }}</div>
                  </div>
                </div>

                <!--items list-->
                <div class="mt-2 val">Items List</div>
                <div
                  class="item-summery-content"
                  v-for="(item, index) in state.orderData?.items"
                  :key="`item${index}`"
                >
                  <div class="content">
                    <p class="ordered-item">{{ `${item.qty} x ${item.name}` }}</p>
                    <p class="ordered-item-price">{{ currency((item.price * item.qty)) }}</p>
                  </div>
                  <div
                    class="content text-info"
                    v-for="(addon, idx) in item.addons"
                    :key="`addon${index}_${idx}`"
                  >
                    <p class="ordered-item addon-name">{{ `${addon.name}` }}</p>
                    <p class="ordered-item-price">{{ currency((addon.price * item.qty)) }}</p>
                  </div>
                </div>

                <!--set menus list-->
                <div
                  v-if="state.orderData.set_menus.length"
                >
                  <i class="mb-2 text-muted">Set Menus</i>
                  <div
                    class="item-summery-content"
                    v-for="(setMenu, index) in state.orderData.set_menus"
                    :key="`setMenu${index}`"
                  >
                    <div class="content">
                      <p class="ordered-item fw-bold">{{ `${setMenu.qty} x ${setMenu.name}` }}</p>
                      <p class="ordered-item-price fw-bold">
                        <span class="text-muted" title="Original SetMenu price">{{ currency((setMenu.menu_price * setMenu.qty)) }}/</span>
                        <span title="Total SetMenu price with extra items">{{ currency((setMenu.price * setMenu.qty)) }}</span>
                      </p>
                    </div>
                    <div
                      v-for="(setMenuItem, idx) in setMenu.items"
                      :key="`setMenuItem${index}_${idx}`"
                    >
                      <div class="content">
                        <p class="ordered-item addon-name">{{ `${setMenuItem.name}` }}</p>
                        <p class="ordered-item-price">{{ currency((setMenuItem.price * setMenu.qty)) }}</p>
                      </div>

                      <div
                        class="content text-info"
                        v-for="(setMenuItemAddon, idx) in setMenuItem.addons"
                        :key="`setMenuItemAddon${index}_${idx}`"
                      >
                        <p class="ordered-item addon-name">{{ `${setMenuItemAddon.name}` }}</p>
                        <p class="ordered-item-price">{{ currency(setMenuItemAddon.price * setMenu.qty) }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item-summery-footer">
                  <div class="billing-item">
                    <p class="bill-type">Items</p>
                    <p class="bill-price">{{ currency(state.itemsTotal) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Addons</p>
                    <p class="bill-price">{{ currency(state.addonsTotal) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Set Menus</p>
                    <p class="bill-price">{{ currency(state.setMenusTotal) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Sub Total</p>
                    <p class="bill-price">{{ currency(getSubTotal()) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Delivery Charge</p>
                    <p class="bill-price">{{ currency(state.orderData.delivery_amount) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Discount</p>
                    <p class="bill-price">{{ currency(state.orderData.discount_amount) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Coupon</p>
                    <p class="bill-price">{{ currency(state.orderData.coupon_amount) }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Points Used</p>
                    <p class="bill-price"> {{
                        `${state.orderData['points_used']} (${currencySymbol}${0})`
                      }}
                    </p>
                  </div>
                  <div
                    v-if="state.orderData.adjustment_amount > 0"
                    class="billing-item">
                    <p class="bill-type">Adjustment</p>
                    <p class="bill-price">{{ `${currencySymbol}${state.orderData.adjustment_amount}` }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Grand Total</p>
                    <p class="bill-price">{{ currency(state.orderData.total) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--     /Item Details       -->

            <!--     User Details       -->
            <div class="col-lg-6 col-sm-12 col-12 user-details">
              <div class="lead">Customer Details</div>
              <div class="table-responsive">
                <table class="table table-actives rounded bg-light">
                  <tbody class="fs18">
                  <tr>
                    <td colspan="2" class="divider">Personal Info</td>
                  </tr>
                  <tr>
                    <td class="key">Name</td>
                    <td class="val">{{ state.orderData.customer_name }}</td>
                  </tr>
                  <tr>
                    <td class="key">Phone</td>
                    <td class="val fw-bold">
                      <a :href="`tel:${state.orderData.customer_phone}`" class="text-secondary">
                        <i class="icon-phone-call" />
                        {{ state.orderData.customer_phone }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="key">Email</td>
                    <td class="val fw-bold">
                      <a :href="`mailto:${state.orderData.customer_email}`" class="text-secondary">
                        <i class="icon-mail" />
                        {{ state.orderData.customer_email }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="divider">Address Info</td>
                  </tr>
                  <tr>
                    <td class="key">House No.</td>
                    <td class="val">{{ state.orderData.house_no }}</td>
                  </tr>
                  <tr>
                    <td class="key">Street</td>
                    <td class="val">{{ state.orderData.street_name }}</td>
                  </tr>
                  <tr>
                    <td class="key">County</td>
                    <td class="val">{{ state.orderData.county }}</td>
                  </tr>
                  <tr>
                    <td class="key">City</td>
                    <td class="val">{{ state.orderData.city }}</td>
                  </tr>
                  <tr>
                    <td class="key">Postcode</td>
                    <td class="val">{{ state.orderData.postcode }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--     /User Details       -->
          </div>
        </div>
      </div>

      <el-collapse
        v-model="state.activeTab"
        accordion
      >
        <el-collapse-item title="Order Details" name="1">
          <div class="table-responsive">
            <table class="table table-actives bg-light">
              <tbody class="fs18">
              <tr>
                <td class="key">Type</td>
                <td class="val">{{ state.orderData.type }}</td>
              </tr>
              <tr>
                <td class="key">Items</td>
                <td class="val">
                  {{ getItemsCount() }}
                  <span class="help">(excluding qty. & addons)</span>
                </td>
              </tr>
              <tr>
                <td class="key">Note</td>
                <td class="val">{{ state.orderData.note }}</td>
              </tr>
              <tr>
                <td class="key">Ordered at</td>
                <td class="val">{{ formatDTime(state.orderData.created_at, true) }}</td>
              </tr>
              <tr>
                <td class="key">Requested for</td>
                <td class="val" v-if="!state.orderData.requested_time_is_asap">{{
                    formatDTime(state.orderData.requested_delivery_time, true)
                  }}
                </td>
                <td class="val" v-else>ASAP</td>
              </tr>
              <tr>
                <td class="key">Delivered at</td>
                <td class="val" v-if="state.orderData.delivery_time">
                  {{ formatDTime(state.orderData.delivery_time) }}
                </td>
              </tr>
              <tr>
                <td class="key">Last Updated</td>
                <td class="val">{{ formatDTime(state.orderData.updated_at, true) }}</td>
              </tr>
              <tr>
                <td class="key">Status</td>
                <td
                  class="val text-uppercase"
                  v-html="getOrderStatus(state.orderData)"
                />
              </tr>
              </tbody>
            </table>
          </div>
        </el-collapse-item>

        <el-collapse-item title="Payment Details" name="2">
          <div class="table-responsive">
            <table class="table table-actives bg-light">
              <tbody class="fs18">
              <tr>
                <td class="key">Subtotal</td>
                <td class="val">{{ currencySymbol }} {{ state.orderData.subtotal.toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="key">Delivery Charge</td>
                <td class="val">
                  <i class="icon-plus"></i> £ {{ state.orderData.delivery_amount.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td class="key">Discount</td>
                <td class="val">
                  <i class="icon-minus"></i> {{ currencySymbol }} {{ state.orderData.discount_amount.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td class="key">Coupon</td>
                <td class="val">
                  <i class="icon-minus"></i> {{ currencySymbol }} {{ state.orderData.coupon_amount.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td class="key">Total</td>
                <td class="val">{{ currencySymbol }} {{ state.orderData.total.toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="key">Payment Type</td>
                <td class="val text-uppercase">{{ state.orderData.payment_type }}</td>
              </tr>
              <tr>
                <td class="key">Payment Status</td>
                <td class="val text-uppercase">
                  <span v-html="renderStatus(state.orderData.status_payment? 'Paid' : 'Due')"></span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </ModalComp>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import { currency, currencySymbol, renderStatus } from '@/Mixins/appHelper';
import ModalComp from '@/components/Util/ModalComp';
import { orderStatusesNew, orderTypes, orderStatuses, formatDate, formatISODate } from '@/utils/Helper';
import Message from 'primevue/message';
import { APP_TIMEZONE, DATETIME_FORMAT_1 } from '@/utils/constants';

export default defineComponent({
  name: 'OrderAction',
  emits: ['update:modelValue'],
  components: { ModalComp, Message },
  props: {
    modelValue: { // v-modal
      type: Boolean
    },
    order: {
      type: Object
    }
  },

  setup (props, { emit }) {

    const state = reactive({
      modalShown: false,

      itemsTotal: 0,
      addonsTotal: 0,
      setMenusTotal: 0,
      orderData: props.order, // props.order,
      activeTab: 1,
    });

    const getSubTotal = () => {

      let itemsTotalCount = 0;
      let addonsTotalCount = 0;

      const setMenusTotal = state.orderData.set_menus.reduce((acc, setMenu) => {
        return acc + (setMenu.price * setMenu.qty);
      }, 0);

      // get items total
      const total = state.orderData.items
        .reduce((n, item) => {

          const itemsTotal = item.price * item.qty;

          const addonsTotal = item.addons.reduce((total, addon) => {
            return total + (addon.price * item.qty);
          }, 0);

          // set totals separately
          itemsTotalCount += itemsTotal;
          addonsTotalCount += addonsTotal;

          return n + itemsTotal + addonsTotal;

        }, 0)
      ;

      state.itemsTotal = itemsTotalCount;
      state.addonsTotal = addonsTotalCount;

      // get set menus total
      state.setMenusTotal = setMenusTotal;

      return total + setMenusTotal;

    };

    const getItemsCount = () => {
      return state.orderData.items.length + state.orderData.set_menus.length;
    };

    const toggleModal = (showHide) => {
      state.modalShown = showHide;

      if (!showHide) {
        emit('update:modelValue', showHide);
      }
    };

    watch(() => props.order, (nv) => {
      state.orderData = nv;
    });

    // map pops value to state
    watch(() => props.modelValue, (nv) => {
      state.modalShown = nv;
    });

    // track modal close
    watch(() => state.modalShown, (nv) => {
      toggleModal(nv);
    });

    const getOrderStatus = (order) => {

      const orderStatus = (order.type === orderTypes.collection) && (order.status === orderStatuses.delivered)
        ? orderStatusesNew.collected
        : orderStatusesNew[order.status]
      ;

      return renderStatus(orderStatus);
    };

    const formatDTime = (dTimeString, useISODate = false) => {

      if (useISODate) return formatISODate(dTimeString, DATETIME_FORMAT_1, APP_TIMEZONE);

      return formatDate(dTimeString, DATETIME_FORMAT_1);
    };

    return {
      state,
      formatDTime,
      currency,
      getSubTotal,
      getItemsCount,
      toggleModal,
      currencySymbol,
      renderStatus,
      getOrderStatus,
    };
  },
});
</script>

<style lang="scss" scoped>

tbody {
  font-size: 15px;

  td.divider {
    background-color: #f8f8f8;
  }
}


.order-actions {
  .user-details {
    font-size: 15px;
  }

}

.key {
  background-color: #f8f8f8;
}


.val {
  font-weight: 600;

  .help {
    color: darkgrey;
    font-size: 13px;
    font-style: italic;
  }
}

.table .divider {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}

.item-summery-content .content {
  margin-bottom: 0;

  .addon-name {
    margin-left: 23px !important;
  }
}

.item-summery {
  row-gap: 8px;
  font-size: 15px;
}

</style>

<style lang="scss">
.el-collapse-item {
  margin-bottom: 10px;

  .el-collapse-item__header {
    background-color: #e5e5e5 !important;
    padding: 10px 10px 10px 20px;
    font-size: 15px;
    border-radius: 5px;

    .el-collapse-item__arrow {
      font-size: 25px;
      font-weight: 700;
    }
  }
}
</style>
