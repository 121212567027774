<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Orders</div>
          <SwitchComp
            name="order-filter"
            :checked="state.filterEnabled"
            enable-text="Filter Enabled"
            disable-text="Filter Disabled"
            @change="(nv) => state.filterEnabled = nv"
          />
        </div>

        <div class="card-body pt-0" v-loading="state.loading">

          <FadeSlideAnimation
            initial-animation
          >
            <div v-if="state.filterEnabled" class="filters-panel">
              <div class="filters-controls">
                <div class="row">
                  <div class="form-group col-sm-12 col-md-12 col-lg-4">
                    <label>Customer</label>
                    <input
                      type="text"
                      class="form-control fs15"
                      placeholder="Customer Name"
                      v-model="state.filters.customer"
                      @keyup="triggerFilter"
                    />
                  </div>

                  <div class="form-group col-sm-12 col-md-6 col-lg-4">
                    <div class="row">
                      <div class="col-md-9 col-sm-8 col-xl-9">
                        <label>Ordered Date Range</label>
                        <Datepicker
                          v-model="state.filters.orderPlacedDate"
                          placeholder="Start Date - End Date"
                          :format="formatDateRanger"
                          @update:modelValue="triggerFilter"
                          range>
                        </Datepicker>
                      </div>
                      <div class="col-md-3 col-sm-4 col-xl-3">
                        <button
                          type="button"
                          class="btn btn-success mt-4 text-nowrap fs15 today-btn"
                          @click="filterSetToday(true)"
                        >
                          Today
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-sm-6 col-md-6 col-lg-4">
                    <div class="row">
                      <div class="col-md-9">
                        <label>Requested Date Range</label>
                        <Datepicker
                          v-model="state.filters.orderForDate"
                          @update:modelValue="triggerFilter"
                          placeholder="Start Date - End Date"
                          :format="formatDateRanger"
                          range>
                        </Datepicker>
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="btn btn-primary mt-4 text-nowrap fs15 today-btn"
                          @click="filterSetToday(false)"
                        >
                          Today
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <label>Payment Type</label>
                    <select
                      class="form-control text-capitalize fs15"
                      v-model="state.filters.paymentType"
                      @change="triggerFilter"
                    >
                      <option selected value="">Choose...</option>
                      <option
                        v-for="(status, index) in state.paymentTypes"
                        :key="`payment-type-${index}`"
                      >
                        {{ status }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label>Order Type</label>
                    <select
                      class="form-control text-capitalize fs15"
                      v-model="state.filters.orderType"
                      @change="triggerFilter"
                    >
                      <option selected value="">Choose...</option>
                      <option
                        v-for="(status, index) in state.orderTypes"
                        :key="`order-type-${index}`"
                      >
                        {{ status }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-12 col-md-2">
                    <label>Status</label>
                    <select
                      class="form-control text-capitalize fs15"
                      v-model="state.filters.status"
                      @change="triggerFilter"
                    >
                      <option selected value="">Choose...</option>
                      <option
                        v-for="(newStatus, status) in state.filterStatuses"
                        :key="`order-${status}`"
                        :value="status"
                      >
                        {{ newStatus === 'delivered' ? 'delivered / collected' : newStatus }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-2 col-md-3 col-lg-auto float-end">
                    <button
                      type="button"
                      class="btn btn-secondary mt-4 text-nowrap fs15"
                      @click="resetFilter"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div class="filters-summery row">
                <div class="col fs20">
                  Total <strong>{{ currencySymbol }}{{ state.filterTotalAmount }}</strong> in
                  <strong> {{ state.filterTotalOrders }} </strong> orders
                </div>
              </div>

            </div>
          </FadeSlideAnimation>

          <ItemTable
            v-loading="state.filterLoading"
            :data="state.filteredOrders"
            :columns="columnMap"
            :show-actions="isAuthorized(permissionsGroup.order)"
            show-export
            export-file-name="Orders"
            :export-data="state.filteredOrdersForExport"
          >
            <template #customer_name="{item}">
              <span
                :class="[
                  'cursor-pointer',
                  !item.last_email_send_status && 'text-danger'
                ]"
              >
                {{ item.customer_name }}
              </span>
            </template>

            <template #total="{item}">
              <span :class="{'text-danger': !item.last_email_send_status}">
                {{ currency(item.total) }}
              </span>
            </template>

            <template #requested_delivery_time="{item}">
              <span :class="{'text-danger': !item.last_email_send_status}">
                {{
                  item.requested_time_is_asap
                    ? 'ASAP'
                    : dateFormat(item.requested_delivery_time)
                }}
              </span>
            </template>

            <template #status="{item}">
              <span
                v-if="isAuthorized(permissions.order_update)"
                v-html="renderMappedStatus(item)"
                @click="handleSelectedOrderAction(item, 'status-update')"
                class="cursor-pointer"
              />
              <span
                v-else
                v-html="renderMappedStatus(item)"
                class="cursor-pointer"
              />
            </template>

            <template #action="{item}" v-if="isAuthorized(permissionsGroup.order)">
              <div class="text-nowrap">

                <ViewButton
                  v-if="isAuthorized(permissions.order_update)"
                  @click="handleSelectedOrderAction(item, 'view')"
                />

                <PrintButton
                  v-if="isAuthorized(permissions.order_print)"
                  :loading="state.buttonLoading.print && selectedOrder.id === item.id"
                  @click="handleSelectedOrderAction(item, 'print')"
                />

                <DeleteButton
                  v-if="isAuthorized(permissions.order_delete)"
                  @click="handleSelectedOrderAction(item, 'delete')"
                />

              </div>
            </template>
          </ItemTable>

        </div>

        <OrderAction
          v-model="showModal"
          :order="selectedOrder"
        />

        <StatusUpdateAction
          v-if="showUpdateAction"
          title="Update Order Status"
          :current-option="selectedOrder.status"
          :options="state.statuses"
          :show-action="showUpdateAction"
          :current-item="selectedOrder"
          action-type="order"
          @cancelled="handleUpdateAction"
          @updated="handleUpdateAction"
        />

      </div>
    </div>
  </div>
</template>


<script>

import ItemTable from '@/components/Util/ItemTable';
import { useStore } from 'vuex';
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import SwitchComp from '@/components/Util/SwitchComp';
import OrderAction from '@/components/Order/OrderAction';
import StatusUpdateAction from '@/components/Util/StatusUpdateAction';
import Order from '@/Services/Order';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';
import { formatDateRanger, setDateTime, orderStatuses, orderStatusesNew, orderTypes } from '@/utils/Helper';
import { currency, currencySymbol, dateFormat, renderStatus } from '@/Mixins/appHelper';
import ViewButton from '@/components/Util/ListActions/ViewButton';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import PrintButton from '@/components/Util/ListActions/PrintButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';
import _ from 'lodash';

export default defineComponent({
  name: 'ordersPage',
  components: {
    Datepicker,
    DeleteButton,
    PrintButton,
    ViewButton,
    FadeSlideAnimation,
    StatusUpdateAction,
    OrderAction,
    SwitchComp,
    ItemTable,
  },

  setup () {

    const store = useStore();

    const deleteData = (id) => {

      SweetAlert.confirmError({
        text: 'This will delete this order permanently',
        preConfirm: async () => {

          try {

            let response = await store.dispatch('order/deleteOrder', id);

            Toaster.successAlt({
              title: 'Deleted',
              message: response.data.message || 'Order successfully Deleted'
            });

          } catch (e) {

            Toaster.error({
              title: 'Error',
              message: e.message || 'Something went wrong'
            });

          }
        }
      });

    };

    const state = reactive({
      loading: computed(() => store.state.order.loading),
      orders: computed(() => store.getters['order/orders']),
      permissions: computed(() => store.getters['order/permissions']),
      statuses: computed(() => {
        const statuses = store.getters['order/statuses'];

        if (!selectedOrder.value?.type) return [];

        const isCollectionOrder = (selectedOrder.value?.type === 'collection');

        return statuses.map(status => {
          if (isCollectionOrder) {
            if (status === 'delivering') return false;
            if (status === 'delivered') return 'collected';
          }
          return status;
        }).filter(status => status);

      }),
      buttonLoading: {
        print: false
      },

      filteredOrders: [],
      filteredOrdersForExport: [],

      filterLoading: false,
      filterEnabled: false,
      filters: {
        customer: '',
        orderPlacedDate: '',
        orderForDate: '',
        paymentType: '',
        orderType: '',
        status: '',
      },
      filterStatuses: computed(() => {
        const statuses = store.state.order.data.statuses || [];

        const newStatuses = {};

        if (!statuses.length) return;

        // mapping with new statuses
        statuses.map(status => {
          newStatuses[status] = orderStatusesNew[status];
        });

        return newStatuses;
      }),
      paymentTypes: ['card', 'cash'],
      orderTypes: ['collection', 'delivery'],
      filterTotalOrders: 0,
      filterTotalAmount: 0,
    });

    const showUpdateAction = ref(false);
    const handleUpdateAction = () => {
      showUpdateAction.value = !showUpdateAction.value;
    };

    const selectedOrder = ref({});
    const handleSelectedOrderAction = async (item, action) => {

      selectedOrder.value = item;

      if (action === 'view') {
        showModal.value = true;
      }

      if (action === 'print') {
        state.buttonLoading.print = true;
        try {
          await Order.print(selectedOrder.value.id);
          Toaster.info({
            title: 'Printing is processing',
            message: 'Printing will start soon. look at the printer'
          });
        } catch (e) {

          Toaster.error({
            message: 'Something went wrong.'
          });

        }
        state.buttonLoading.print = false;
      }

      if (action === 'status-update') {
        handleUpdateAction();
      }

      if (action === 'delete') {
        deleteData(selectedOrder.value.id);
      }
    };

    const showModal = ref(false);

    let filteredOrder = [...state.orders];

    const columnMap = [
      {
        field: 'customer_name',
        header: 'Customer',
        sortable: false,
      },
      {
        field: 'total',
        header: 'Cost',
        sortable: true,
      },
      {
        field: 'requested_delivery_time',
        header: 'Requested',
        sortable: true,
      },
      {
        field: 'type',
        header: 'Type',
        sortable: true,
      },
      {
        field: 'status',
        header: 'Status',
        event: 'status',
        sortable: true,
      },
    ];

    const getOrder = async () => {

      const filteredOrders = store.getters['order/orders'];

      if (filteredOrders.length && store.state.order.dataFetched) return;

      await store.dispatch('order/getOrders');
    };

    const resetFilter = () => {
      state.filterLoading = true;

      state.filters = {
        customer: '',
        orderPlacedDate: '',
        orderForDate: '',
        paymentType: '',
        orderType: '',
        status: ''
      };

      state.filteredOrders = [...state.orders];
      state.filteredOrdersForExport = prepareExportData(state.orders);

      setTimeout(() => state.filterLoading = false, 600);
    };

    const filterSetToday = (isOrderPlacedDate = false) => {

      const today = new Date();

      if (isOrderPlacedDate) {

        state.filters.orderPlacedDate = [today, today];

      } else {

        state.filters.orderForDate = [today, today];

      }

      triggerFilter()
    };

    const triggerFilter = () => {

      if (state.filterEnabled) {
        state.filterLoading = true;
      }

      let filteredData = [...state.orders];

      for (const filter in state.filters) {

        const filterValue = state.filters[filter];

        switch (filter) {
          case 'customer':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.customer_name.toLowerCase().includes(filterValue.toLowerCase()));
            break;

          case 'status':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.status == filterValue);
            break;

          case 'paymentType':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.payment_type == filterValue);
            break;

          case 'orderType':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.type == filterValue);
            break;

          case 'orderPlacedDate':

            const placedDate = state.filters.orderPlacedDate;

            if (!placedDate) break;

            const orderedTimes = {
              from: placedDate[0],
              to: placedDate[1],
            }

            const placeDateStart = setDateTime(new Date(orderedTimes.from), 0, 0, 0);
            const placeDateEnd = setDateTime(new Date(orderedTimes.to), 23, 59, 59);

            filteredData = filteredData.filter(d => {
              return (
                (new Date(d.created_at).getTime() >= new Date(placeDateStart).getTime()) &&
                (new Date(d.created_at).getTime() <= new Date(placeDateEnd).getTime())
              );
            });
            break;

          case 'orderForDate':

            const forDate = state.filters.orderForDate;

            if (!forDate) break;

            const requestedTimes = {
              from: forDate[0],
              to: forDate[1],
            };

            const orderForDateStart = setDateTime(new Date(requestedTimes.from), 0, 0, 0);
            const orderForDateEnd = setDateTime(new Date(requestedTimes.to), 23, 59, 59);

            filteredData = filteredData.filter(d => {

              // handle ASAP orders
              // when requested time is ASAP that order was must be for same day
              if (d.requested_time_is_asap) {
                return (
                  (new Date(d.created_at).getTime() >= new Date(orderForDateStart).getTime()) &&
                  (new Date(d.created_at).getTime() <= new Date(orderForDateEnd).getTime())
                );
              }

              return (
                (new Date(d.requested_delivery_time).getTime() >= new Date(orderForDateStart).getTime()) &&
                (new Date(d.requested_delivery_time).getTime() <= new Date(orderForDateEnd).getTime())
              );
            });

            break;

          default:
            break;
        }

      }

      state.filteredOrders = filteredData;

      // change order statuses to new statuses
      state.filteredOrdersForExport = prepareExportData(filteredData);

      if (state.filterEnabled) {
        setTimeout(() => state.filterLoading = false, 600);
      }

    }

    function prepareExportData (orders) {
      return orders.map(order => {

        const orderClone = _.cloneDeep(order);

        // collected to delivered collection order
        if (orderClone.type === orderTypes.collection && orderClone.status === orderStatuses.delivered) {
          orderClone.status = orderStatusesNew.collected;
          return orderClone;
        }

        // map new statuses
        orderClone.status = orderStatusesNew[orderClone.status];
        return orderClone;
      });
    }

    const renderMappedStatus = (item) => {
      const itemType = item?.type;
      const itemStatus = item?.status;

      let displayStatus = itemStatus;

      // for delivery order & for status status delivered
      if (itemType === 'collection' && itemStatus === 'delivered') displayStatus = 'collected';

      // for status processing
      if (itemStatus === 'processing') displayStatus = 'accepted';

      // for status delivering
      if (itemStatus === 'delivering') displayStatus = 'on the way';

      return renderStatus(displayStatus, 'p-2 fs15');
    };

    watch(() => state.filterEnabled, (nv) => {
      // if filter disabled remove all filter
      if (!nv) resetFilter();
    });

    watch(() => state.filteredOrders, (nv) => {
      state.filterTotalOrders = nv.length;

      const total = nv.reduce((pv, cv) => {
        return (pv + cv.total);
      }, 0);

      state.filterTotalAmount = total.toFixed(2);
    });

    // re-apply filter when base orders changes
    watch(() => state.orders, () => {
      triggerFilter();
    });

    // lifecycle hooks
    onMounted(async () => {
      await getOrder();
      await triggerFilter();

      // reset new counts
      store.dispatch('app/resetNewOrdersCount');

      // stop new order sound
      store.dispatch('app/stopNewOrderSound');
    });

    return {
      formatDateRanger,
      filteredOrder,
      state,
      columnMap,
      renderMappedStatus,
      currencySymbol,
      showModal,
      selectedOrder,
      handleSelectedOrderAction,
      dateFormat,
      showUpdateAction,
      handleUpdateAction,
      resetFilter,
      filterSetToday,
      triggerFilter,
      currency,
      permissionsGroup,
      permissions,
    };
  }
});
</script>

<style scoped lang="scss">

.table-badge {
  font-size: 12px;
}

#orders-table td {
  font-size: 16px;
  font-weight: 600;
}

.order-action {
  cursor: pointer;
}


.filters-panel {
  margin: 0 -20px;

  .filters-controls {
    background-color: #dddddd;
    padding: 5px 15px;

  }

  .filters-summery {
    background-color: #ededed;
    padding: 5px 15px;
    margin: 0;
  }
}
.today-btn {
  margin-left: -24px;
  height: 36px;
  border: none;
  border-radius: 0;
}
input.dp__pointer.dp__input.dp__input_icon_pad.dp__input_focus {
  border-radius: 0;
  border: none;
}

@media screen and (max-width: 576px) {
  .filters-panel button {
    display: block;
    width: 100%;
  }
  .today-btn {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  body {
    .today-btn {
      margin-left: 0;
    }
  }
}
</style>
