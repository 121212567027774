<template>
  <ButtonComp
    :icon="iconClass"
    label=""
    :outlined="outlined"
    :class="btnClasses"
    :loading="loading"
    button-type="secondary"
  />
</template>

<script>
import ButtonComp from '@/components/Util/ButtonComp';

export default {
  name: 'PrintButton',
  components: { ButtonComp },
  props: {
    iconClass: {
      type: String,
      default: 'icon-print'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    btnClasses: {
      type: String,
      default: 'me-3 fs25 px-4'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

</style>
